<template>
  <Layout>
    <PageHeader
      :title="
        $route.name == 'add-hospital-timings' ? 'Add Timing' : 'Edit Timing'
      "
      :items="items"
    ></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div v-for="(data, index) in form.schedule" :key="index">
                <div class="row">
                  <h6 class="col-12">{{ data?.day }}</h6>

                  <!-- Start Time Field -->
                  <b-form-group
                    id="input-group-start-time"
                    label-for="input-start-time"
                    class="col-3 mb-2"
                  >
                    <label style="font-weight: 500">
                      Start Time <span style="color: red">*</span>
                    </label>
                    <!-- <date-picker
                      id="input-start-time"
                      type="time"
                      v-model="data.start_time"
                      format="HH:mm:ss"
                      placeholder="hh:mm:ss"
                      :confirm="true"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].start_time.$pending &&
                          !$v.form.schedule.$each[index].start_time.$model,
                      }"
                    ></date-picker> -->
                    <b-form-timepicker
                      v-model="data.start_time"
                      locale="en"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].start_time.$pending &&
                          !$v.form.schedule.$each[index].start_time.$model,
                      }"
                    ></b-form-timepicker>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.schedule.$each[index].start_time.$model
                      "
                      class="invalid-feedback"
                    >
                      Start time is required.
                    </div>
                  </b-form-group>

                  <!-- End Time Field -->
                  <b-form-group
                    id="input-group-end-time"
                    label-for="input-end-time"
                    class="col-3 mb-2"
                  >
                    <label style="font-weight: 500">
                      End Time <span style="color: red">*</span>
                    </label>
                    <!-- <date-picker
                      id="input-end-time"
                      type="time"
                      v-model="data.end_time"
                      format="HH:mm:ss"
                      placeholder="hh:mm:ss"
                      :confirm="true"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].end_time.$pending &&
                          !$v.form.schedule.$each[index].end_time.$model,
                      }"
                    ></date-picker> -->
                    <b-form-timepicker
                      v-model="data.end_time"
                      locale="en"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].end_time.$pending &&
                          !$v.form.schedule.$each[index].end_time.$model,
                      }"
                    ></b-form-timepicker>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.schedule.$each[index].end_time.$model
                      "
                      class="invalid-feedback"
                    >
                      End time is required.
                    </div>
                  </b-form-group>

                  <!-- Swap Hour Field -->
                  <b-form-group
                    id="input-group-swap-hour"
                    label-for="input-swap-hour"
                    class="col-3 mb-2"
                  >
                    <label style="font-weight: 500">
                      Swap Hour <span style="color: red">*</span>
                    </label>
                    <b-form-input
                      id="input-swap-hour"
                      type="number"
                      v-model="data.swap_hours"
                      placeholder="Enter Swap Hour"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].swap_hours.$pending &&
                          !$v.form.schedule.$each[index].swap_hours.$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.schedule.$each[index].swap_hours.$model
                      "
                      class="invalid-feedback"
                    >
                      Swap Hour is required.
                    </div>
                  </b-form-group>

                  <!-- Swap Time Field -->
                  <b-form-group
                    id="input-group-swap-time"
                    label-for="input-swap-time"
                    class="col-3 mb-2"
                  >
                    <label style="font-weight: 500">
                      Swap Time <span style="color: red">*</span>
                    </label>
                    <!-- <date-picker
                      id="input-swap-time"
                      type="time"
                      v-model="data.swap_time"
                      format="HH:mm:ss"
                      placeholder="hh:mm:ss"
                      :confirm="true"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].swap_time.$pending &&
                          !$v.form.schedule.$each[index].swap_time.$model,
                      }"
                    ></date-picker> -->
                    <b-form-timepicker
                      v-model="data.swap_time"
                      locale="en"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.schedule.$each[index].swap_time.$pending &&
                          !$v.form.schedule.$each[index].swap_time.$model,
                      }"
                    ></b-form-timepicker>
                    <div
                      v-if="
                        submitted &&
                        !$v.form.schedule.$each[index].swap_time.$model
                      "
                      class="invalid-feedback"
                    >
                      Swap Time is required.
                    </div>
                  </b-form-group>

                  <!-- Day Status Field -->
                  <div class="col-md-12 mb-0">
                    <div style="display: flex; gap: 10px">
                      <b-form-checkbox v-model="data.day_status" class="mb-1">
                        Day Status
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <!-- Submit Button -->
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                <span v-if="this.$route.name == 'add-hospital-timings'">
                  Save Data
                </span>
                <span v-else> Update Data </span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
// import DatePicker from "vue2-datepicker";
import clinicMixin from "@/mixins/ModuleJs/hospital-timings";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
    // DatePicker,
  },
  mixins: [MixinRequest, clinicMixin],
  validations() {
    return {
      form: {
        schedule: {
          $each: {
            start_time: { required },
            end_time: { required },
            swap_hours: { required },
            swap_time: { required },
          },
        },
      },
    };
  },
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
};
</script>
