var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.$route.name == 'add-hospital-timings' ? 'Add Timing' : 'Edit Timing',"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_vm._l((_vm.form.schedule),function(data,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"col-12"},[_vm._v(_vm._s(data?.day))]),_c('b-form-group',{staticClass:"col-3 mb-2",attrs:{"id":"input-group-start-time","label-for":"input-start-time"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v(" Start Time "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-timepicker',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.schedule.$each[index].start_time.$pending &&
                        !_vm.$v.form.schedule.$each[index].start_time.$model,
                    },attrs:{"locale":"en"},model:{value:(data.start_time),callback:function ($$v) {_vm.$set(data, "start_time", $$v)},expression:"data.start_time"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.schedule.$each[index].start_time.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Start time is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-3 mb-2",attrs:{"id":"input-group-end-time","label-for":"input-end-time"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v(" End Time "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-timepicker',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.schedule.$each[index].end_time.$pending &&
                        !_vm.$v.form.schedule.$each[index].end_time.$model,
                    },attrs:{"locale":"en"},model:{value:(data.end_time),callback:function ($$v) {_vm.$set(data, "end_time", $$v)},expression:"data.end_time"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.schedule.$each[index].end_time.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" End time is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-3 mb-2",attrs:{"id":"input-group-swap-hour","label-for":"input-swap-hour"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v(" Swap Hour "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.schedule.$each[index].swap_hours.$pending &&
                        !_vm.$v.form.schedule.$each[index].swap_hours.$model,
                    },attrs:{"id":"input-swap-hour","type":"number","placeholder":"Enter Swap Hour"},model:{value:(data.swap_hours),callback:function ($$v) {_vm.$set(data, "swap_hours", $$v)},expression:"data.swap_hours"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.schedule.$each[index].swap_hours.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Swap Hour is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-3 mb-2",attrs:{"id":"input-group-swap-time","label-for":"input-swap-time"}},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v(" Swap Time "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-timepicker',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.schedule.$each[index].swap_time.$pending &&
                        !_vm.$v.form.schedule.$each[index].swap_time.$model,
                    },attrs:{"locale":"en"},model:{value:(data.swap_time),callback:function ($$v) {_vm.$set(data, "swap_time", $$v)},expression:"data.swap_time"}}),(
                      _vm.submitted &&
                      !_vm.$v.form.schedule.$each[index].swap_time.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Swap Time is required. ")]):_vm._e()],1),_c('div',{staticClass:"col-md-12 mb-0"},[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(data.day_status),callback:function ($$v) {_vm.$set(data, "day_status", $$v)},expression:"data.day_status"}},[_vm._v(" Day Status ")])],1)])],1),_c('hr')])}),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-hospital-timings')?_c('span',[_vm._v(" Save Data ")]):_c('span',[_vm._v(" Update Data ")])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }